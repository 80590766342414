.app.modal-true {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.dropzone {
  min-height: 100%;
}

.welcome {
  text-align: center;
}

@media print {
  .control-panel {
    display: none;
  }
}

.control-panel {
  text-align: center;
  z-index: 7;
  background-color: #f5f5f5;
  padding: 10px 0;
  position: sticky;
  top: 0;
}

.control-panel > * {
  margin: 10px 0;
  transition: all 1s ease-in-out;
}

.control-panel > .visible-false {
  opacity: 0;
  max-height: 0;
  margin: 0;
}

.control-panel > .visible-true {
  opacity: 1;
  max-height: 100%;
}

.lds-dual-ring {
  width: 2.5em;
  height: 2.5em;
  display: inline-block;
}

.lds-dual-ring:after {
  content: " ";
  border: 5px solid #6495ed;
  border-color: #6495ed #0000;
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  margin: 1px;
  animation: 1.2s linear infinite lds-dual-ring;
  display: block;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.modal-title a.about-gravatar {
  padding-top: .2rem;
  font-size: smaller;
  font-style: italic;
  font-weight: normal;
  display: inline-block;
}

.gravatar-title {
  margin-right: 1.5rem;
}

.gravatar label, .gravatar .label {
  margin-bottom: 1rem;
  display: block;
}

.gravatar .freetext {
  width: 100%;
  height: 200px;
}

.gravatar .freetext:disabled {
  background-color: #dcdcdc;
}

.gravatar .fallback {
  cursor: pointer;
  border: 3px solid #fff;
  margin: 2px;
  padding: 1px;
  transition: all .2s ease-in-out;
}

.gravatar .fallback:hover {
  transform: scale(1.1);
}

.gravatar .fallback.selected-true {
  border-color: #6495ed;
}

@media print {
  .dev-bar .wip-bar {
    display: none;
  }
}

.image-upload-options {
  font-weight: bold;
}

.image-upload-options a {
  color: #6495ed;
}

.image-upload-options > :after {
  content: "●";
  padding-left: 1rem;
  padding-right: 1rem;
}

.image-upload-options > :last-child:after {
  content: "";
  padding: 0;
}

.modal-overlay {
  z-index: 8;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-overlay.visible-false {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear .25s, opacity .25s, transform .25s;
  transform: scale(1.1);
}

.modal-overlay.visible-true {
  visibility: visible;
  opacity: 1;
  transition: visibility linear, opacity .25s, transform .25s;
  transform: scale(1);
}

.modal-prompt {
  background-color: #fff;
  border-radius: .5rem;
  max-height: calc(100vh - 200px);
  position: absolute;
  top: 50%;
  left: 50%;
  overflow-y: auto;
  transform: translate(-50%, -50%);
}

.modal-title {
  color: #fff;
  z-index: 9;
  background-color: #6495ed;
  align-items: center;
  padding: 1rem;
  font-weight: bold;
  display: flex;
  position: sticky;
  top: 0;
}

.modal-title.visible-false {
  display: none;
}

.modal-title .dismiss {
  text-align: center;
  cursor: pointer;
  color: #000;
  background-color: #d3d3d3;
  border-radius: .25rem;
  width: 1.5rem;
  margin-left: auto;
  line-height: 1.5rem;
  transition: all .2s ease-in-out;
}

.modal-title .dismiss:after {
  content: "×";
}

.modal-title .dismiss:hover {
  background-color: #a9a9a9;
}

.modal-content {
  margin: 1.5rem;
}

.modal-actions {
  text-align: center;
  margin: 1.5rem;
}

.modal-actions a {
  color: #6495ed;
}

.modal-actions button {
  color: #fff;
  background-color: #6495ed;
  border: none;
  border-radius: 5px;
  height: 2.5rem;
  padding: 0 3rem;
  font-size: 1rem;
  font-weight: bold;
  transition: all .2s ease-in-out;
}

.modal-actions button:hover:enabled {
  cursor: pointer;
  transform: scale(1.1);
}

.modal-actions button:disabled {
  cursor: not-allowed;
  background-color: #dcdcdc;
}

.modal-actions .error-message {
  color: red;
  padding-bottom: 1rem;
}

.modal-actions.visible-false, .modal-actions .visible-false {
  display: none;
}

@media print {
  .tag.nil-role-true .role-name {
    display: none;
  }
}

.tag.nil-role-true .role-name {
  color: #d3d3d3;
  background-color: #fff;
  border: 1px dashed gray;
}

.tag.nil-role-true .role-name:before {
  content: "role";
}

.tag.nil-role-true .role-name:focus:before {
  content: "";
}

.tag.nil-role-true .role-name:focus {
  color: #000;
}

.options-bar {
  line-height: 2em;
}

.options-bar * {
  font-size: small;
}

.options-bar .group {
  white-space: nowrap;
  margin: 0 10px;
}

.options-bar label {
  padding: 0 5px;
}

.options-bar .label {
  margin-right: 5px;
}

.shape-option {
  cursor: pointer;
  border: 3px solid gray;
  width: 12px;
  height: 12px;
  margin: 0 4px -4px 0;
  transition: all .2s ease-in-out;
  display: inline-block;
}

.shape-option:hover {
  transform: scale(1.2);
}

.shape-option.selected-true {
  border-color: #6495ed;
}

input[type="number"] {
  width: 50px;
}

.role-customiser {
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  margin: 5px;
  padding: 5px;
  display: inline-block;
}

.role-name {
  color: #fff;
  text-transform: uppercase;
  border-radius: 25px;
  padding: 3px 7px 2px;
  font-size: 14px;
  font-weight: bold;
}

.color-picker {
  cursor: pointer;
  width: 10px;
  margin-left: 5px;
  margin-right: 2px;
  padding-top: 3px;
  padding-bottom: 2px;
  font-size: smaller;
  display: inline-block;
}

.color-picker:before {
  color: gray;
  content: "▼";
}

body {
  -moz-user-select: none;
  color: #444;
  margin: 0;
  padding: 0;
  font-family: helvetica, sans-serif;
  -webkit-print-color-adjust: exact !important;
}

a {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

span[contenteditable="true"] {
  cursor: text;
}

.tag-list {
  grid-auto-rows: 1fr;
  margin: 5px;
  display: grid;
}

.tag {
  text-align: center;
  color: #000;
  border: 1px dashed silver;
  padding: 5px 0;
  font-weight: bold;
}

.tag * {
  margin: 0 auto;
}

.tag .layout .group {
  margin: 8px;
  display: block;
}

.tag-name {
  margin-bottom: 5px;
}

.tag-name:empty {
  border: 1px dashed gray;
  width: 20px;
}

.tag .role-name {
  border: 1px solid #0000;
  max-width: 100%;
  margin: 0 3px;
}

.tag-image {
  box-sizing: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-clip: content-box;
  border: 5px solid #0000;
  width: 100%;
  height: 100%;
}

.tag-name {
  border: 1px solid #0000;
  max-width: 100%;
  margin-left: 3px;
  margin-right: 3px;
  padding-top: 2px;
  padding-bottom: 1px;
  display: inline-block;
}

.tag.passive .tag-image {
  filter: grayscale();
  border-color: silver;
}

.tag.passive.nil-role-false .role-name {
  background-color: silver;
}

.tips .tip {
  margin-bottom: 3em;
}

.tip .heading {
  font-weight: bold;
}

/*# sourceMappingURL=index.c5ef59e1.css.map */
